<template>
  <div>
    <v-dialog v-model="dialog" max-width="700" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ dialogTitle }}</span
          ><v-divider class="mx-4" inset vertical></v-divider>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="3">
                <v-switch
                  class="pl-6 mt-1"
                  v-model="item.Free"
                  inset
                  dense
                  color="primary"
                  :label="item.Free == true ? 'ฟรี' : 'ไม่ฟรี'"
                >
                </v-switch>
              </v-col>
              <v-col cols="12" sm="3">
                <v-switch
                  class="pl-6 mt-1"
                  v-model="item.Vat"
                  inset
                  dense
                  color="primary"
                  :label="item.Vat == true ? 'ภาษี' : 'ไม่ภาษี'"
                >
                </v-switch>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  v-model="item.Product"
                  :items="productList"
                  item-value="ProductId"
                  item-text="SearchName"
                  :return-object="true"
                  dense
                  outlined
                  label="สินค้า"
                  ref="ProductId"
                  @change="productChange(item.Product)"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model.number="item.Qty"
                  label="จำนวน"
                  type="number"
                  dense
                  outlined
                  required
                  reverse
                  ref="Qty"
                  @keydown.enter="NextFocus('UnitId')"
                  @change="calTotal"
                  @focus="SelectAll('Qty')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-autocomplete
                  v-model="item.Unit"
                  :items="unitList"
                  item-value="UnitId"
                  item-text="UnitName"
                  :return-object="true"
                  dense
                  outlined
                  label="หน่วย"
                  ref="UnitId"
                  @change="unitChange(item.Unit)"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model.number="item.Ratio"
                  label="อัตราส่วน"
                  type="number"
                  dense
                  outlined
                  filled
                  required
                  reverse
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model.number="item.QtyInvent"
                  label="สต๊อก"
                  type="number"
                  dense
                  outlined
                  filled
                  required
                  reverse
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model.number="item.Price"
                  label="ราคา"
                  type="number"
                  dense
                  outlined
                  required
                  reverse
                  ref="Price"
                  @keydown.enter="NextFocus('Qty')"
                  @change="calTotal"
                  @focus="SelectAll('Price')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model.number="item.DiscountPercent"
                  label="ส่วนลด(%)"
                  type="number"
                  dense
                  outlined
                  required
                  reverse
                  ref="DiscountPercent"
                  @keydown.enter="NextFocus('DiscountAmount')"
                  @change="discountPercentChange(item.DiscountPercent)"
                  @focus="SelectAll('DiscountPercent')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model.number="item.DiscountAmount"
                  label="ส่วนลด(บาท)"
                  type="number"
                  dense
                  outlined
                  required
                  reverse
                  ref="DiscountAmount"
                  @keydown.enter="NextFocus('Remark')"
                  @change="discountAmountChange(item.DiscountAmount)"
                  @focus="SelectAll('DiscountAmount')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model.number="item.DiscountTotal"
                  label="ส่วนลดรวม"
                  type="number"
                  dense
                  outlined
                  filled
                  required
                  reverse
                  readonly
                  ref="DiscountTotal"
                  @keydown.enter="NextFocus('Remark')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model.number="item.Total"
                  label="ยอดรวม"
                  type="number"
                  dense
                  outlined
                  filled
                  required
                  reverse
                  readonly
                  ref="Total"
                  @keydown.enter="NextFocus('MFGDate')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="9">
                <v-text-field
                  v-model="item.Remark"
                  label="หมายเหตุ"
                  dense
                  outlined
                  required
                  ref="Remark"
                  @keydown.enter="NextFocus('MFGDate')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu
                  v-model="menuMFGDate"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="item.MFGDateStr"
                      label="วันที่ผลิต"
                      prepend-icon="mdi-calendar"
                      :readonly="isEdit"
                      dense
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      ref="MFGDate"
                      @keydown.enter="NextFocus('EXPDate')"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="item.MFGDateStr"
                    locale="th-TH"
                    @input="menuMFGDate = false"
                  ></v-date-picker> </v-menu
              ></v-col>
              <v-col cols="12" sm="6">
                <v-menu
                  v-model="menuEXPDate"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="item.EXPDateStr"
                      label="วันที่หมดอายุ"
                      prepend-icon="mdi-calendar"
                      :readonly="isEdit"
                      dense
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      ref="EXPDate"
                      @keydown.enter="NextFocus('DiscountPercent')"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="item.EXPDateStr"
                    locale="th-TH"
                    @input="menuEXPDate = false"
                  ></v-date-picker> </v-menu
              ></v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary--text" @click="cancel"> ยกเลิก </v-btn>
          <v-btn class="primary" @click="submit"> บันทึก </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import baseEnum from "@js/enum";
import { mapActions, mapState } from "vuex";

export default {
  // name: "DialogSettingRawMaterial",
  props: ["modal", "item", "itemIndex"],
  data() {
    return {
      enumStatus: baseEnum.status.data,
      dialog: false,
      itemEdit: {},
      indexEdit: -1,
      unitList: [],
      productList: [],
      menuMFGDate: false,
      menuEXPDate: false,
      product: {},
      unit: {},
    };
  },
  mounted() {
    this.getUnitList();
    this.getProductList();
  },
  computed: {
    ...mapState("auth", ["user"]),
    dialogTitle() {
      return this.indexEdit == -1 ? "เพิ่ม" : "แก้ไข";
    },
    isEdit() {
      return this.indexEdit == -1 ? false : true;
    },
  },
  methods: {
    ...mapActions("settings", [
      "actProductGets",
      "actUnitGets",
      "actProductGet",
    ]),
    discountAmountChange(val) {
      let priceTotal = this.item.Price * this.item.Qty;
      let maxDiscount =
        priceTotal - priceTotal * this.item.DiscountPercent * 0.01;
      this.item.DiscountAmount = val > maxDiscount ? maxDiscount : val;
      this.calTotal();
    },
    discountPercentChange(val) {
      this.item.DiscountPercent = val < 0 ? 0 : val > 100 ? 100 : val;
      this.calTotal();
    },
    calTotal() {
      let priceTotal = this.item.Price * this.item.Qty;
      this.item.QtyInvent = this.item.Qty * this.item.Ratio;
      this.item.DiscountTotal =
        priceTotal * this.item.DiscountPercent * 0.01 +
        this.item.DiscountAmount;
      this.item.Total = priceTotal - this.item.DiscountTotal;
    },
    // closeModal(parm) {
    //   this.$emit("close", parm);
    // },
    cancel() {
      let parm = { modal: false, item: {}, index: -2 };
      this.$emit("close", parm);
    },
    getUnitList() {
      this.actUnitGets().then(({ status, data }) => {
        if (status == 200 && data.success) {
          this.unitList = data.val;
        } else {
          this.Error(data.message);
        }
      });
    },
    unitChange(val) {
      this.item.UnitId = val.UnitId;
      this.item.UnitName = val.UnitName;
      this.item.Ratio = val.Ratio;
      this.$refs.["Price"].focus();
      this.calTotal();
    },
    productChange(val) {
      this.item.ProductId = val.ProductId;
      this.item.ProductName = val.ProductName;
      this.item.Vat = val.Vat;
      this.$refs.["Qty"].focus();
      this.calTotal();
    },
    getProductList() {
      this.actProductGets().then(({ status, data }) => {
        if (status == 200 && data.success) {
          this.productList = data.val;
        } else {
          this.Error(data.message);
        }
      });
    },
    submit() {
      // console.log(this.item);
      let parm;
      if (this.item.ProductId == undefined) {
        this.Warning("กรุณาเลือกสินค้า");
        return false;
      }
      // if (this.item.Price == "") {
      //   this.Warning("กรุณาระบุราคา");
      //   return false;
      // }
      if (this.item.Qty == "") {
        this.Warning("กรุณาระบุจำนวน");
        return false;
      }
      if (this.item.UnitId == undefined) {
        this.Warning("กรุณาเลือกหน่วย");
        return false;
      }
      if (this.item.Free && this.item.Total != 0) {
        this.Warning("กรุณาตรวจสอบราคาเนื่องจากเป็นสินค้าฟรี");
        return false;
      }
      if (!this.item.Free && this.item.Total == 0) {
        this.Warning("กรุณาตรวจสอบราคาเนื่องจากไม่เป็นสินค้าฟรี");
        return false;
      }
      if (this.indexEdit == -1) {
        //เพิ่ม
        parm = { modal: false, item: this.item, index: -1 };
        // this.itemEdit.CreatedBy = this.user.UserId;
        // this.itemEdit.ModifiedBy = this.user.UserId;
        // this.actFoodMenuCreate(this.itemEdit).then(({ status, data }) => {
        //   if (status == 200 && data.success) {
        //     this.closeModal();
        //   } else {
        //     this.Error(data.message);
        //   }
        // });
      } else {
        //แก้ไข
        parm = { modal: false, item: this.item, index: this.itemIndex };
        // this.itemEdit.ModifiedBy = this.user.UserId;
        // this.actFoodMenuUpdate(this.itemEdit).then(({ status, data }) => {
        //   if (status == 200 && data.success) {
        //     this.closeModal();
        //   } else {
        //     this.Error(data.message);
        //   }
        // });
      }
      // this.unit = "";
      // this.product = "";
      this.$emit("close", parm);
    },
  },
  watch: {
    modal() {
      this.dialog = this.modal;
    },
    item() {
      const current = new Date();
      this.itemEdit = this.item;
      if (this.itemEdit.MFGDateStr == "") {
        this.itemEdit.MFGDateStr = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
      }
    },
    itemIndex() {
      this.indexEdit = this.itemIndex;
    },
    "item.Free": function (val) {
      if (val) {
        this.item.Price = 0;
        this.item.DiscountPercent = 0;
        this.item.DiscountAmount = 0;
        this.item.DiscountTotal = 0;
        this.item.Total = 0;
      }
    },
    // "item.Total": function(val) {
    //   this.item.Free = val > 0 ? false : true;
    // },
    "item.Unit": function (val) {
      this.item.UnitId = val.UnitId;
      this.item.UnitName = val.UnitName;
    },
    "item.Product": function (val) {
      this.item.ProductId = val.ProductId;
      this.item.ProductName = val.ProductName;
    },
  },
};
</script>

<style></style>
